<template>
  <div>
    <div class="form-group">
      <!-- First password field -->
      <h3 class="form-input-title">{{fieldTitle}}</h3>
      <label :for="FIELD_NAME_PASSWORD" class="input-label">
        <input :class="{ 'field-error': apiError || errorMessages.length > 0 }"
               :placeholder="placeholder"
               @input="handleInput"
               @focus="() => updateDisplayedIcon('active')"
               @focusout="() => updateDisplayedIcon('inactive')"
               :id="FIELD_NAME_PASSWORD"
               :name="FIELD_NAME_PASSWORD"
               type="password"
               class="form-input">
        <!-- Input icon - There are 3 states: inactive, active and error. -->
        <img v-if="displayedIcon" :src="displayedIcon" :class="{'has-error': errorMessages.length > 0}" class="input-icon"
             alt=""/>
      </label>

<!--      <div v-show="apiError || errorMessages.length > 0">-->
<!--        &lt;!&ndash; give priority to api error msg over vue validator error msg, until user inputs again &ndash;&gt;-->
<!--        <p v-if="apiError" class="text-error">{{apiError}}</p>-->
<!--        <p v-else class="text-error">{{errorMessages[0]}}</p>-->
<!--      </div>-->
    </div>
    <div class="form-group password-confirm">
      <!-- NOTE! Make sure your confirmation field's fieldName is passed here into name prop -->
      <h3 class="form-input-title">{{fieldTitleConfirm}}</h3>
      <label :for="FIELD_NAME_CONFIRMATION" class="input-label">
        <input :class="{ 'field-error': apiError || errorMessages.length > 0 }"
               :placeholder="placeholderConfirm"
               @input="handleInput"
               @focus="() => updateDisplayedIcon('active')"
               @focusout="() => updateDisplayedIcon('inactive')"
               :id="FIELD_NAME_CONFIRMATION"
               :name="FIELD_NAME_CONFIRMATION"
               type="password"
               class="form-input">
        <!-- Input icon - There are 3 states: inactive, active and error. -->
        <img v-if="displayedIcon" :src="displayedIcon" :class="{'has-error': errorMessages.length > 0}" class="input-icon"
             alt=""/>
      </label>
      <div v-show="apiError || errorMessages.length > 0">
        <!-- give priority to api error msg over vue validator error msg, until user inputs again -->
        <p v-if="apiError" class="text-error">{{apiError}}</p>
        <p v-else class="text-error">{{errorMessages[0]}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  /** here keep it for Vue 3 **/
  // import {ref, watch} from 'vue';
  import {ref, watch} from '@vue/composition-api';
  import Validator from "@/utils/yo-validator/YoValidator";

  const FIELD_NAME_PASSWORD = 'password';
  const FIELD_NAME_CONFIRMATION = 'confirmation';
  const RULE_NAME_CONFIRMATION = 'passwordConfirm';

  export default {
    name: 'PasswordConfirmation',
    props: {
      fieldTitle: {
        type: String,
        required: false,
        default: 'Password'
      },
      fieldTitleConfirm: {
        type: String,
        required: false,
        default: 'Confirm password'
      },
      placeholder: {
        type: String,
        required: false,
        default: 'Enter password'
      },
      placeholderConfirm: {
        type: String,
        required: false,
        default: 'Re-enter your password'
      },
      debounce: {
        type: Number,
        required: false,
        default: 0
      },
      apiErrorText: {
        required: false,
        type: String
      },
      icon: {
        // type: String,
        required: false,
      },

      // These can be used to give an initial value to the input fields.
      valuePassword: {
        type: String,
        required: false
      },
      valueConfirmation: {
        type: String,
        required: false
      }
    },
    setup(props) {
      /** validator returned error messages **/
      const {errorMessages, handleInput} = Validator.register({
        fieldName: FIELD_NAME_PASSWORD,
        rules: `required|${RULE_NAME_CONFIRMATION}:@${FIELD_NAME_CONFIRMATION}|passwordStrength`
      });

      /** watch api error text from parent **/
      const apiError = ref('');
      watch(() => props.apiErrorText, (newVal) => {
        apiError.value = newVal.trim();
      });

      /** Icon **/
      watch(errorMessages, (newVal) => {
        if (newVal.length) {
          updateDisplayedIcon('error');
        }
      });

      const displayedIcon = ref(props.icon?.inactive);

      function updateDisplayedIcon(icon) {
        displayedIcon.value = props?.icon[icon];
      }

      return {
        errorMessages,
        apiError,
        handleInput,
        FIELD_NAME_PASSWORD,
        FIELD_NAME_CONFIRMATION,

        /** Icon **/
        displayedIcon,
        updateDisplayedIcon
      }
    }
  }
</script>

<style scoped lang="scss">
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";

.password-confirm {
  margin-top: rem(25);
}
</style>
