<template>
  <div class="auth-page-wrapper">
    <!-- Logo -->
    <img src="../../assets/icons/x2/img_billy_logo@2x.png" class="auth-logo" alt="Logo"/>

    <h1 class="create-title">Create password</h1>
    <p class="create-subtitle">The password must contain at least 8 characters.</p>

    <div class="create-form">
      <FormWrapper :send-form-data="validateForm" :mode="3">
        <template #form-content>

          <!-- Password field -->
          <PasswordConfirmation :icon="passwordIcons"/>
        </template>

        <!-- Submit button -->
        <template #button-submit="{isInvalid}">
          <button :disabled="isInvalid"
                  :class="{'button-disabled': isInvalid}"
                  type="submit"
                  class="button-main auth-button button-create">
            Save password
          </button>
        </template>

      </FormWrapper>
    </div>
  </div>
</template>

<script>
import {computed} from "@vue/composition-api"
import FormWrapper from '@/components/yo-form/FormWrapper'
import InputIcons from "@/models/input/InputIcons";
import PasswordConfirmation from "@/components/yo-form/PasswordConfirmation";
import {ROUTE_NAMES_AUTH} from "@/router/modules/auth";
import {RESET_PASSWORD} from "@/store/auth/actions";

export default {
  name: "CreatePassword",
  components: {
    PasswordConfirmation,
    FormWrapper,
  },
  setup(_, {root}) {
    const store = root.$store;
    const router = root.$router;
    const route = root.$route;

    /** Route parameters **/
    const {id, token} = route.params;

    /** Input icons **/
    const isLoading = computed(() => store.getters.isPageLoading);

    /** Form validation **/
    function validateForm(formData) {
      createPassword(formData).then(response => {
        if (response) {
          router.push({name: ROUTE_NAMES_AUTH.LOGIN});
        }
      });
    }

    /** Create password **/
    function createPassword(formData) {
      return store.dispatch(RESET_PASSWORD, {id, token, password: formData.password});
    }

    /** Input icons **/
    const emailIcons = new InputIcons(require('../../assets/icons/svg/ic_user_active.svg'), require('../../assets/icons/svg/ic_user_inactive.svg'), require('../../assets/icons/svg/ic_user_error.svg'));
    const passwordIcons = new InputIcons(require('../../assets/icons/svg/ic_password_active.svg'), require('../../assets/icons/svg/ic_password_inactive.svg'), require('../../assets/icons/svg/ic_password_error.svg'));

    /** Errors **/
    const authError = computed(() => store.getters.getAuthError);

    return {
      /** Loading **/
      isLoading,

      /** Form validation **/
      validateForm,

      /** Input icons **/
      emailIcons,
      passwordIcons,

      /** Errors **/
      authError
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

.create {
  &-title {
    font-size: rem(20);
    margin-bottom: rem(10);
  }

  &-subtitle {
    font-size: rem(16);
    margin-bottom: rem(35);
  }
}

.button-create {
  margin-top: rem(40);
}
</style>
